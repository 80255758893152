import React from 'react';
import Swal from 'sweetalert2';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("access_key", "f6a163dd-cdb4-4a4b-83cf-eef4d842a974");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });

      const data = await res.json();

      if (data.success) {
        Swal.fire({
          title: "Thanks for Reaching Out!",
          text: "Message Sent Successfully!",
          icon: "success",
        });
      } else {
        throw new Error(data.message || "Submission failed");
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message || "Failed to send message",
        icon: "error",
      });
    }
  };

  return (
    <div className="cards">
      <h1>At Savvy, we Thrive in Providing your Tailored Solutions</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          {/* About Us Text Card */}
          <div className="about-us-card-wrapper">
            <div className="about-us-card">
              <h2>About Us</h2>
              <p>Savvy 91PS Innovates is a Liberian-owned Technology Company founded in 2022. At Savvy 91PS Innovates, we are committed to creating impactful solutions that bridge gaps, solve challenges, and inspire transformation by harnessing technology to empower businesses and communities, improve livelihoods, and shape a better future.</p>
              <br />
              <strong>
                <h3>Mission</h3>
              </strong>
              <p>To drive innovation through cutting-edge technology, sustainable practices, and creative solutions that empower businesses, communities, and individuals to thrive in a rapidly evolving world.</p>
              <br />
              <strong>
                <h3>Vision</h3>
              </strong>
              <p>To be a global leader in technology-driven innovation, fostering a future where creativity and sustainability intersect to create a positive and lasting impact.</p>
            </div>
          </div>
          <hr className="category-separator" />

          {/* First Set of Cards */}
          <h1>Services</h1>
          <ul className="cards__items">
            <CardItem
              src="images/mad.png"
              text="Bring your ideas to life with intuitive mobile apps designed to engage users and drive growth. We create seamless, high-performance apps that offer personalized experiences across all devices"
              label="Mobile App Solutions"
              path="/"
            />
            <CardItem
              src="images/wad.png"
              text="Transform your business with powerful web applications that are fast, scalable, and secure. Our web solutions help you connect with customers and streamline operations for success."
              label="Web App Solutions"
              path="/"
            />
            <CardItem
              src="images/ai.png"
              text="Unlock the power of Artificial Intelligence to make smarter decisions, optimize processes, and drive innovation. From predictive analytics to automation, we build AI solutions that work for you."
              label="AI_enabled Solutions"
              path="/"
            />
            <CardItem
              src="images/cloud.png"
              text="Elevate your business with cloud computing solutions that offer flexibility, scalability, and security. Stay ahead of the competition with reliable, cost-effective cloud services that enhance performance."
              label="Cloud Solutions"
              path="/"
            />
          </ul>
          <hr className="category-separator" />

          {/* Projects Section */}
          <h1>Projects</h1>
          <ul className="cards__items">
            <CardItem
              src="images/gro.png"
              text="Agroo AI leverages technology to improve agriculture in underserved regions. It provides solutions for crop disease detection, market access, and climate-smart farming, using precision tools and drones to boost productivity and foster growth in rural communities"
              label="Agroo AI"
              path="/"
            />
            <CardItem
              src="images/bp.png"
              text="BirthPass: A mobile app solution that enables citizens to easily apply for and issue birth certificates remotely, streamlining the process and improving accessibility"
              label="BirthPath"
              path="/"
            />
            <CardItem
              src="images/sm.png"
              text="A cutting-edge device designed to store electrical energy, monitor usage, and efficiently manage electricity consumption, empowering users to track and control their energy use"
              label="Smart Energy Meter"
              path="/"
            />
          </ul>
          <hr className="category-separator" />

          {/* Team Section */}
          <h1>Team</h1>
          <ul className="cards__items">
            <CardItem
              src="/images/irene1.jpeg"
              text="Irene Tina Dennis is a talented software engineer, graphic designer, and critical thinker. She holds a BSc degree in Software Engineering. "
              label="Founder & CEO"
              path="/"
            />
            <CardItem
              src="/images/me.jpeg"
              text="Matthias B.E Luogon is a seasoned, software engineer, entrepreneur, with more than 5 years of research experience. He holds an MSc in Artificial Intelligence Engineering. "
              label="Co-Founder & CTO"
              path="/"
            />
            <CardItem
              src="/images/js.jpg"
              text="Joshua T Sangar is a talented software engineering, and an aspiring data scientist. He holds a BSc in software engineering."
              label="CIO"
              path="/"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src="/images/luc.png"
              text="D. Lucius Flomo is an aspiring system administrator and a promising cyber security analyst. He holds a BSc in Network Engineering."
              label="System Admin"
              path="/"
            />
            <CardItem
              src="/images/den.jpeg"
              text="Daniel Thompson is a talented software engineer, passionate about the work of God. He holds a BSc in Software Engineering."
              label="COO"
              path="/"
            />
            <CardItem
              src="/images/koko.jpg"
              text="Diana Kokulo is a passionate Human Resource professional, software engineer, and aspiring entrepreneur. She holds a BSc in Software Engineering."
              label="HR"
              path="/"
            />
            <CardItem
              src="/images/han.jpg"
              text="Henrietta Summerville has a strong passion for market analysis, and an aspiring desire for business development. She holds a BSc in Software Engineering."
              label="Marketing Analyst"
              path="/"
            />
          </ul>
          <hr className="category-separator" />

          {/* Contact Form Section */}
          <div className="contact-form-card">
            <h2>Contact Us</h2>
            <p>Have questions or want to learn more? Reach out to us, and we'll get back to you as soon as possible.</p>
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="name" placeholder="Your Full Name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" placeholder="Your Email Address" required />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message" placeholder="Your Message" rows="4" required></textarea>
              </div>
              <button type="submit" className="submit-btn">Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
