// App.js
import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom';
import Products from './components/pages/Products';
import Footer from './components/Footer';
import Market from './components/pages/market';
import ProductPage from './components/ProductPage';
// import ImageSlider from './components/ImageSlider';
import Irene from './components/Irene';



function ProductPageWrapper() {
  const { productName } = useParams();
  return <ProductPage product={productName} />;
}

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/products' component={Products} />
          <Route path='/market' component={Market} />
          <Route path='/irene' component={Irene} />
          <Route path='/products/:productName' component={ProductPageWrapper} />
        </Switch>
        {/* <ImageSlider /> */}
        <Footer />
      </Router>
    </>
  );
}

export default App;
