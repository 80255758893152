import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { FaFacebook, FaTwitter, FaGithub, FaTiktok } from 'react-icons/fa';
import { TbBrandThreads } from 'react-icons/tb';

import './HeroSection.css';

const HeroSection = () => {
  const slides = [
    { src: '/images/aa.png', alt: 'Slide 1', caption: 'Empowering Innovation' },
    { src: '/images/bb.png', alt: 'Slide 2', caption: 'Creating Impactful Solutions' },
    { src: '/images/cc.png', alt: 'Slide 3', caption: 'Transforming Ideas into Reality' },
    { src: '/images/ee.png', alt: 'Slide 1', caption: 'Empowering Innovation' },
    { src: '/images/ff.png', alt: 'Slide 2', caption: 'Creating Impactful Solutions' },
    { src: '/images/gg.png', alt: 'Slide 3', caption: 'Transforming Ideas into Reality' },
    { src: '/images/hh.png', alt: 'Slide 1', caption: 'Empowering Innovation' },
    { src: '/images/ii.png', alt: 'Slide 2', caption: 'Creating Impactful Solutions' },
    { src: '/images/cc.png', alt: 'Slide 3', caption: 'Transforming Ideas into Reality' },
  ];

  return (
    <div className="hero-section">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
        loop={true}
        className="hero-swiper"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="slide">
              <img src={slide.src} alt={slide.alt} className="slide-image" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="hero-overlay">
        <h1>Hi! We're Savvy 91PS Innovates</h1>
        <p>Transforming Ideas into Reality</p>
        <button className="hero-btn contact">Contact Us</button>
        <div className="social-media-icons">
          <a href="https://www.threads.net/?hl=en" aria-label="Threads">
            <TbBrandThreads />
          </a>
          <a href="https://www.facebook.com/Savvy91PSInnovates?mibextid=wwXIfr&mibextid=wwXIfr" aria-label="Facebook">
            <FaFacebook />
          </a>
          <a href="https://x.com/?lang=en&mx=2" aria-label="X (formerly Twitter)">
            <FaTwitter />
          </a>
          <a href="https://github.com/" aria-label="Github">
            <FaGithub />
          </a>
          <a href="https://www.tiktok.com/explore?kuid=3130dfce-c086-437f-9ab0-df56eaf7fd8b&kref=vGWRdzLJnjYf" aria-label="TikTok">
            <FaTiktok />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
