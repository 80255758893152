import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaGithub, FaTiktok } from 'react-icons/fa';
import { TbBrandThreads } from 'react-icons/tb';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join our community for exclusive Tech insights
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Join</Button>
          </form>
        </div>
      </section>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          
          
        </div>
        
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              {/* <img src="/images/savvy_logo.png" alt="Logo" className="navbar-logo-image" /> */}
              {/* <i className='fab fa-typo3' /> */}
            </Link>
          </div>
          <small className='website-rights'>Savvy 91PS Innovate (S9I) © 2025</small>
          <div className='social-icons'>
            <a
              href='https://www.threads.net'
              className='social-icon-link'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='Threads'
            >
              <TbBrandThreads />
            </a>
            <a
              href='https://www.facebook.com/Savvy91PSInnovates?mibextid=wwXIfr&mibextid=wwXIfr'
              className='social-icon-link'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='Facebook'
            >
              <FaFacebook />
            </a>
            <a
              href='https://twitter.com'
              className='social-icon-link'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='X (formerly Twitter)'
            >
              <FaTwitter />
            </a>
            <a
              href='https://github.com'
              className='social-icon-link'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='GitHub'
            >
              <FaGithub />
            </a>
            <a
              href='https://www.tiktok.com'
              className='social-icon-link'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='TikTok'
            >
              <FaTiktok />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
