import React from 'react';


function CardItem({ videoSrc, src, text, label, path }) {
  return (
    <li className="cards__item">
      <a className="cards__item__link" href={path}>
        <figure className="cards__item__pic-wrap" data-category={label}>
          {videoSrc ? (
            <video
              className="cards__item__media"
              src={videoSrc}
              autoPlay
              loop
              muted
              playsInline // Improves compatibility on mobile browsers
              aria-label={label || 'Card Video'}
            />
          ) : (
            <img
              className="cards__item__media"
              src={src}
              alt={label || 'Card Image'}
            />
          )}
        </figure>
        <div className="cards__item__info">
          <h5 className="cards__item__text">{text}</h5>
        </div>
      </a>
    </li>
  );
}

export default CardItem;
